<template>
  <div class="header">
    <div class="header__logo">
      <transition name="slide-fade" mode="out-in" :duration="300">
        <div class="title">{{ title }}</div>
      </transition>
    </div>
    <div class="header__menu">
      <!--      <el-menu-->
      <!--        mode="horizontal"-->
      <!--        :default-active="currentPath"-->
      <!--        background-color="#4073fa"-->
      <!--        router-->
      <!--      >-->
      <!--        <template v-for="(nav, idx) in navigator">-->
      <!--          <el-menu-item :key="idx" :index="nav.path" v-if="!nav.meta.hidden">-->
      <!--            <span>{{ nav.meta.title }}</span>-->
      <!--          </el-menu-item>-->
      <!--        </template>-->
      <!--      </el-menu>-->
    </div>
    <div class="header__right">
      <div></div>
      <el-dropdown @command="onCommand">
        <div class="user">
          <div class="name">{{ userInfo.name }}</div>
          <i class="el-icon-arrow-down"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import config from "@/config";

import { asyncRoutes } from "@/router";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo"]),
    title() {
      return config.title;
    },
    navigator() {
      return asyncRoutes;
    },
    currentPath() {
      return this.$route.meta?.parentPath;
    },
  },
  methods: {
    onCommand(command) {
      switch (command) {
        case "logout":
          this.logout();
          break;
      }
    },
    logout() {
      this.$store.dispatch("actionLogout").then(() => {
        this.$store.commit("SET_PERMISSION", {});
        this.$router.push("/account/login");
      });
    },
  },
};
</script>

<style lang="scss">
.header {
  height: 100%;
  background-color: #4073fa !important;
  display: flex;
  align-items: center;
  padding: 0 16px;

  &__logo {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;

    .image {
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }

    .title {
      color: #fff;
      font-size: 14px;
    }
  }

  &__menu {
    flex: 1;
    overflow: hidden;

    .el-menu {
      display: flex;

      .el-menu-item {
        color: #ffffff;
        border: none;
        height: 50px;
        line-height: 50px;
        padding: 0 6px;

        &:not(.is-disabled):focus,
        &:not(.is-disabled):hover {
          color: #ffffff;
          background: transparent !important;

          span {
            background-color: rgba($color: #f2f2f2, $alpha: 0.2) !important;
          }
        }

        & span {
          font-size: 13px;
          padding: 6px 16px;
          border-radius: 4px;
        }

        &.is-active {
          span {
            color: #ffffff;
            padding: 6px 16px;
            background-color: rgba($color: #f2f2f2, $alpha: 0.2) !important;
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;

    .user {
      height: 50px;
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;

      .avatar {
        margin-right: 10px;
      }

      .name {
        margin-right: 4px;
      }
    }
  }
}
</style>
